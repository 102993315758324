@use 'variables' as var;

// Breakpoint definitions
@mixin for-phone {
    @media (max-width:var.$phone-width) {
        @content;
    }
}

@mixin for-tablet {
    @media (min-width: (var.$phone-width + 1)) and (max-width: var.$tablet-width) {
        @content;
    }
}

@mixin for-medium-screen {
    @media (min-width: (var.$tablet-width + 1)) and (max-width: var.$medium-width) {
        @content;
    }
}

@mixin for-large-screen {
    @media (min-width: (var.$medium-width + 1)) and (max-width: var.$large-width) {
        @content;
    }
}

@mixin for-xtra-large-screen {
    @media (min-width: (var.$xtra-large-width + 1)) {
        @content;
    }
}

////////////////////

@mixin clearfix {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}
