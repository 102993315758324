$spacings: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10;
$positions : top, bottom, left, right;
$units: px, rem;

@each $spacing-key in $spacings {
    @each $position-key in $positions {
        @each $unit-key in $units {
            .#{$position-key}-#{$spacing-key}#{$unit-key} {
                #{$position-key} : #{$spacing-key}#{$unit-key};
            }
        }
    }
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.pos-fix {
  position: fixed;
}

.pos-sticky {
  position: sticky;
}
