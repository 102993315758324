.display-flex {
  display: flex;

  &-inline {
    display: inline-flex;
  }
}

.justify-center {
  @extend .display-flex;
  justify-content: center;
}

.justify-between {
  @extend .display-flex;
  justify-content: space-between;
}

.justify-right {
  @extend .display-flex;
  justify-content: flex-end;
}

.justify-left {
  @extend .display-flex;
  justify-content: flex-start;
}

.justify-around {
  @extend .display-flex;
  justify-content: space-around;
}

.align-center {
  @extend .display-flex;
  align-items: center;
}

.align-right {
  @extend .display-flex;
  align-items: flex-end;
}

.align-left {
  @extend .display-flex;
  align-items: flex-start;
}

.display-flex-column {
  @extend .display-flex;
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-grow-one {
  flex-grow: 1;
}

.flex-text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: initial;
  width: 90%;
}

.flex-center {
  @extend .display-flex;
  justify-content: center;
  align-items: center;
}
