*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  // box-sizing: inherit;
}

html,
body {
  height: 100%;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  box-sizing: border-box;
}
