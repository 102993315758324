@use  '../../abstracts/colors' as colors;
.cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
                0 8px 10px 1px rgba(0, 0, 0, 0.14),
                0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
    opacity: 0;
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag {

    .image-container {
        height: 24px;
        width: 24px;
        &.large {
            height: 80px;
            width: 80px;
            font-size: 80px;
        }
    }

    &.mat-mdc-card {
        box-sizing: border-box;
    }
}