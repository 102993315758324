@use '../../abstracts/mixins' as mixins;

.pt-s {
  padding-top: 0.5rem;
}

.pt-m {
  padding-top: 1rem;
}

.pt-l {
  padding-top: 1.5rem;
}

.pr-s {
  padding-right: 0.5rem;
}

.pr-m {
  padding-right: 1rem;
}

.pr-l {
  padding-right: 1.5rem;
}

.pb-s {
  padding-bottom: 0.5rem;
}

.pb-m {
  padding-bottom: 1rem;
}

.pb-l {
  padding-bottom: 1.5rem;
}

.pl-s {
  padding-left: 0.5rem;
}

.pl-m {
  padding-left: 1rem;
}

.pl-l {
  padding-left: 1.5rem;
}

.pa-s {
  padding: 0.5rem;
}

.pa-m {
  padding: 1rem;
}

.pa-l {
  padding: 1.5rem;
}

.pv-l {
  padding: 1.5rem 0;
}

.ph-l {
  padding: 0 1.5rem;
}


@include mixins.for-phone {
  .pa-phone-0 {
    padding: 0 !important;
  }
}