@use '../abstracts/colors' as colors;

body {
  font-family: Franklin-Gothic-Pro-Demi,sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.7;
  color: colors.$color-dark;
}

.overlay {
    background: colors.$color-grey-dark;
    opacity: 0.6;
}

.material-icons {
    color: colors.$color-dark;
}

.primary-text {
    color: colors.$color-primary-medium;
}

.white-text {
    color: colors.$color-white;
}

.fw-500 {
    font-weight: 500;
}

.pointer {
    cursor: pointer;
}

.disable {
    opacity: .7;
    pointer-events: none;
    cursor: not-allowed;
}

.rounded-corner {
    border-radius: 5px;
}

.background-white {
    background-color: colors.$color-white;
}

.underline {
    text-decoration: underline;
}