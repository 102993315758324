@use '../../abstracts//variables' as var;
@use 'sass:math';

$initial-width-px: var.$initial-width-px;
$initial-width-percent: var.$initial-width-percent;
$initial-width-vw: var.$initial-width-vw;

$initial-height-px: var.$initial-height-px;
$initial-height-percent: var.$initial-height-percent;
$initial-height-vh: var.$initial-height-vh;

$max-limit: var.$max-limit;
$max-limit-px: var.$max-limit-px;
$step-size: var.$step-size;

// Width in px
@while $initial-width-px <= $max-limit-px {
    .width-#{$initial-width-px}-px {
        width: #{$initial-width-px}px !important;
    }

    $initial-width-px: $initial-width-px + $step-size;
}

// Width in %
@while $initial-width-percent <= $max-limit {
    .width-#{$initial-width-percent}-percent {
        width: percentage( math.div($initial-width-percent, $max-limit)) !important;
    }

    $initial-width-percent: $initial-width-percent + $step-size;
}

// Width in vw
@while $initial-width-vw <= $max-limit {
    .width-#{$initial-width-vw}-vw {
        width: #{$initial-width-vw}vw !important;
    }

    $initial-width-vw: $initial-width-vw + $step-size;
}

// Height in px
@while $initial-height-px <= $max-limit-px {
    .height-#{$initial-height-px}-px {
        height: #{$initial-height-px}px !important;
    }

    $initial-height-px: $initial-height-px + $step-size;
}

// Height in %
@while $initial-height-percent <= $max-limit {
    .height-#{$initial-height-percent}-percent {
        height: percentage(math.div($initial-height-percent, $max-limit)) !important;
    }

    $initial-height-percent: $initial-height-percent + $step-size;
}

// Height in vh
@while $initial-height-vh <= $max-limit {
    .height-#{$initial-height-vh}-vh {
        height: #{$initial-height-vh}vh !important;
    }

    $initial-height-vh: $initial-height-vh + $step-size;
}
