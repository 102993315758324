@use '@angular/material' as mat;

@include mat.core();

/* For use in src/lib/core/theming/_palette.scss */
$md-default-blue: (
    50 : #e0edff,
    100 : #b3d1ff,
    200 : #80b3ff,
    300 : #4d94ff,
    400 : #267dff,
    500 : #0066ff,
    600 : #005eff,
    700 : #0053ff,
    800 : #0049ff,
    900 : #0038ff,
    A100 : #ffffff,
    A200 : #f2f4ff,
    A400 : #bfc9ff,
    A700 : #a6b4ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
$md-default-purple: (
    50 : #f0e5f8,
    100 : #dbbeed,
    200 : #c393e1,
    300 : #aa67d5,
    400 : #9847cc,
    500 : #8626c3,
    600 : #7e22bd,
    700 : #731cb5,
    800 : #6917ae,
    900 : #560da1,
    A100 : #e5cfff,
    A200 : #c99cff,
    A400 : #ad69ff,
    A700 : #9f50ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);

$my-primary: mat.define-palette($md-default-blue);
$my-accent: mat.define-palette($md-default-purple);

$my-theme: mat.define-light-theme((
    color: (
        primary: $my-primary,
        accent: $my-accent
    )
));

@include mat.core-theme($my-theme);