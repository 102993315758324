@use '../../abstracts/colors' as colors;

$positions: (bottom, top, left, right);
$types: (solid, dashed);
$borderColors: ("dark": colors.$color-dark, "grey": colors.$color-grey-dark);

@each $position-key in $positions {
    @each $type-key in $types {
        @each $color-key, $color-val in $borderColors {
            .border-#{$position-key}-#{$type-key}-#{$color-key} {
                border-#{$position-key}: 1px #{$type-key} #{$color-val}
            }
        }
    }
}
