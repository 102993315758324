// COLORS

$color-primary-light: var(--primary-color-light);
$color-primary-medium: var(--primary-color-medium);
$color-primary-dark: var(--primary-color-dark);

$color-secondary-light: var(--secondary-color-light);
$color-secondary-medium: var(--secondary-color-medium);
$color-secondary-dark: var(--secondary-color-dark);

$color-grey-light: var(--grey-color-light);
$color-grey-medium: var(--grey-color-medium);
$color-grey-dark: var(--grey-color-dark);
$color-grey-border: var(--grey-color-border);
$color-grey-shadow: var(--grey-color-shadow);


$color-light-grey: var(--light-grey-color);
$color-medium-grey: var(--medium-grey-color);
$color-dark-grey: var(--dark-grey-color);

$color-error: var(--error-color);
$color-warning: var(--warning-color);

$color-white: var(--white-color);
$color-dark: var(--dark-color);

