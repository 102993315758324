.mt-s {
  margin-top: 0.5rem;
}

.mt-m {
  margin-top: 1rem;
}

.mt-l {
  margin-top: 1.5rem;
}

.mr-s {
  margin-right: 0.5rem;
}

.mr-m {
  margin-right: 1rem;
}

.mr-l {
  margin-right: 1.5rem;
}

.mb-s {
  margin-bottom: 0.5rem;
}

.mb-m {
  margin-bottom: 1rem;
}

.mb-l {
  margin-bottom: 1.5rem;
}

.ml-s {
  margin-left: 0.5rem;
}

.ml-m {
  margin-left: 1rem;
}

.ml-l {
  margin-left: 1.5rem;
}

.ma-s {
  margin: 0.5rem;
}

.ma-m {
  margin: 1rem;
}

.ma-l {
  margin: 1.5rem;
}

.mv-l {
  margin: 1.5rem 0;
}

.mh-l {
  margin: 0 1.5rem;
}
